var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',_vm._b({staticClass:"cy-project-card unstyled-link",attrs:{"disabled":_vm.loadingProjects}},'v-card',_vm.cardProps,false),[_c('div',{staticClass:"cy-project-card-image"},[_c('CyAvatar',{attrs:{"item":_vm.project}})],1),_c('div',{staticClass:"cy-project-card-content"},[_c('div',{staticClass:"cy-project-card-header",attrs:{"data-cy":"project-card-header"}},[_c('div',{staticClass:"d-flex justify-start align-center"},[_c('h2',[_vm._v(" "+_vm._s(_vm.project.name)+" ")]),(!_vm._.$isEmpty(_vm.projectImportStatus) && !_vm.isProjectImport.succeeded)?_c('CyTag',{staticClass:"cy-project-card__import-status ml-4",attrs:{"small":"","variant":_vm.isProjectImport.importing ? 'accent' : 'error'},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.$emit('show-import-progress-modal', _vm.project)}}},[_vm._v(" "+_vm._s(_vm.projectImportStatusText)+" ")]):_vm._e()],1),_c('div',[_c('CyButton',{attrs:{"icon-only":"","icon":_vm.project.favorite ? 'star' : 'star_outline',"theme":"primary","variant":"tertiary","loading":_vm.isSaving || _vm.loadingProjects},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();return _vm.toggleFavorite(!_vm.project.favorite)}}}),(!_vm._.$isEmpty(_vm.$static.menuItems))?_c('CyMenu',{staticClass:"cy-project-card-header__menu",attrs:{"offset-y":"","items":_vm.$static.menuItems}}):_vm._e()],1)]),_c('div',{staticClass:"cy-project-card-details"},[_c('span',{staticClass:"d-flex"},_vm._l((_vm.project._cloudProviders),function(ref){
var name = ref.name;
var canonical = ref.canonical;
return _c('span',{key:("provider-" + canonical),staticClass:"cy-project-card-details__provider"},[_c('CyIconCredential',{attrs:{"type":canonical,"size":"24","show-tooltip":"","tooltip-direction":"bottom","tooltip-text":name}})],1)}),0),(_vm.canUpdateOwner)?_c('CyFormsAssignOwner',{attrs:{"form-content":_vm.project,"errors":_vm.errors.project,"action-btn-func":_vm.assignNewOwner,"lg":""},on:{"click":function($event){$event.preventDefault();$event.stopPropagation();}}}):_c('CyButton',{attrs:{"to":{
          name: 'member',
          params: {
            id: _vm.project.owner.id,
            backRouteTo: 'projects',
          },
        },"theme":"grey","variant":"tertiary","lg":"","member-link":"","disabled":!_vm.canGoToMember,"readonly":!_vm.canGoToMember},on:{"click":function($event){$event.stopPropagation();}}},[_c('CyMember',{attrs:{"member":_vm.project.owner,"simple":"","sm":""}})],1),_c('span',[_vm._v(_vm._s(_vm.$t('created'))+" "+_vm._s(_vm.$date.$formatTimeAgo(_vm.project.created_at)))]),_c('span',[_vm._v(_vm._s(_vm.$t('updated'))+" "+_vm._s(_vm.$date.$formatTimeAgo(_vm.project.updated_at)))])],1),(_vm.project.description)?_c('p',{staticClass:"cy-project-card-description"},[_vm._v(" "+_vm._s(_vm.project.description)+" ")]):_vm._e(),_c('div',{staticClass:"cy-project-card-env-list"},[_c('CyTagList',{attrs:{"contained":"","tags":_vm.project.environments},scopedSlots:_vm._u([{key:"tag",fn:function(ref){
        var ref_tag = ref.tag;
        var canonical = ref_tag.canonical;
        var color = ref_tag.color;
return [_c('router-link',{key:canonical,attrs:{"to":{
              name: 'environmentOverview',
              params: {
                projectCanonical: _vm.project.canonical,
                envCanonical: canonical,
              },
            }}},[_c('span',{staticClass:"cy-project-card__env mr-2 d-flex align-center"},[_c('CyAvatar',{staticClass:"mr-1",attrs:{"item":{
                  canonical: canonical,
                  icon: 'mdi-layers-outline',
                  color: color,
                },"sm":""}}),_vm._v(" "+_vm._s(canonical)+" ")],1)])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }