<template>
  <CyAlert
    class="mt-2"
    theme="warning"
    :title="$t('noRunningWorkersTitle')"
    :content="message"
    :button-label="$t('viewWorkersBtn')"
    :to="{ name: 'workers' }"/>
</template>

<script>
export default {
  name: 'CyWorkersWarningMessage',
  computed: {
    message () {
      return this.isOrgAdmin
        ? this.$t('noRunningWorkersMessage')
        : `${this.$t('noRunningWorkersMessage')} ${this.$t('contactAdministrator')}`
    },
  },
  i18n: {
    messages: {
      en: {
        contactAdministrator: 'Contact an administrator.',
        noRunningWorkersMessage: 'You have no running workers, your jobs won’t be triggered.',
        noRunningWorkersTitle: 'No workers running',
        viewWorkersBtn: 'View workers',
      },
      es: {
        contactAdministrator: 'Póngase en contacto con un administrador.',
        noRunningWorkersMessage: 'No tiene workers en ejecución, su worker no se activará.',
        noRunningWorkersTitle: 'No hay workers corriendo',
        viewWorkersBtn: 'Ver workers',
      },
      fr: {
        contactAdministrator: 'Veuillez contacter un administrateur.',
        noRunningWorkersMessage: `Aucune tâche ne sera exécutée.`,
        noRunningWorkersTitle: `Aucun worker n'est actif`,
        viewWorkersBtn: 'Voir les workers',
      },
    },
  },
}
</script>
